import Swiper from 'swiper';
import { Navigation, EffectFade, Pagination, Parallax, Thumbs, Zoom, Autoplay } from 'swiper/modules';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        Swiper.use([Navigation, EffectFade, Pagination, Parallax, Thumbs, Zoom, Autoplay]);

        const sliders = document.querySelectorAll('.js-slider-gallery');
        sliders.forEach((slider, index) => {
            const galleryWrapper = slider.closest('.js-gallery-wrapper');
            const modal = galleryWrapper?.querySelector('.modal');
            const parent = slider.closest('.swiper-wrapper');
            const sliderModal = galleryWrapper?.querySelector(
                '.js-slider-gallery-modal',
            );
            const swiperSlder = new Swiper(slider, {
                slidesPerView: 'auto',
                speed: 700,
                parallax: true,
                loop: true,
                grabCursor: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: parent?.querySelector('.swiper-button-next'),
                    prevEl: parent?.querySelector('.swiper-button-prev'),
                },
                pagination: {
                    el: parent?.querySelector('.swiper-pagination'),
                    clickable: true,
                },
                on: {
                    activeIndexChange(swiper) {
                        if (sliderModal !== null && sliderModal !== undefined) {
                            if (!modal?.classList.contains('is-open')) {
                                if (sliderModal?.swiper) {
                                    sliderModal?.swiper.slideTo(
                                        swiper.realIndex,
                                    );
                                }
                            }
                        }
                    },
                },
            });
        });

        const sliderReviews = document.querySelectorAll('.js-carusel-reviews');
        sliderReviews.forEach((slider) => {
            const wrapperCarusel = slider.closest('.reviews-carusel');
            const reviewsSlider = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesPerGroupAuto: true,
                freeMode: true,
                speed: 700,
                loop: false,
                grabCursor: true,

                pagination: {
                    el: wrapperCarusel?.querySelector('.carusel-pagination'),
                    type: "bullets",
                    clickable: true,
                },

                navigation: {
                    nextEl: wrapperCarusel?.querySelector('.custom-carusel-btn--next'),
                    prevEl: wrapperCarusel?.querySelector('.custom-carusel-btn--prev'),
                },

                breakpoints: {
                    1024: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,

                        pagination: {
                            type: 'bullets',
                        },
                    },
                    1280: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        pagination: {
                            type: 'fraction',
                        },
                    },
                },
            });
        });

        const sliderNews = document.querySelectorAll('.js-carusel-news');
        sliderNews.forEach((slider) => {
            const wrapperCarusel = slider.closest('.news-carusel');

            const newsSlider = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesPerGroupAuto: true,
                freeMode: true,
                speed: 700,
                loop: false,
                grabCursor: true,

                pagination: {
                    el: wrapperCarusel?.querySelector('.carusel-pagination'),
                    type: "bullets",
                    clickable: true,
                },

                navigation: {
                    nextEl: wrapperCarusel?.querySelector('.custom-carusel-btn--next'),
                    prevEl: wrapperCarusel?.querySelector('.custom-carusel-btn--prev'),
                },

                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,

                        pagination: {
                            type: 'bullets',
                        },
                    },
                    1280: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        pagination: {
                            type: 'fraction',
                        },
                    },
                },
            });
        });

        const sliderAction = document.querySelectorAll('.js-carusel-action');
        sliderAction.forEach((slider) => {
            const wrapperCarusel = slider.closest('.action-carusel');
            const actionSlider = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesPerGroupAuto: true,
                freeMode: true,
                speed: 700,
                loop: false,
                grabCursor: true,

                pagination: {
                    el: wrapperCarusel?.querySelector('.carusel-pagination'),
                    type: "bullets",
                    clickable: true,
                },

                navigation: {
                    nextEl: wrapperCarusel?.querySelector('.custom-carusel-btn--next'),
                    prevEl: wrapperCarusel?.querySelector('.custom-carusel-btn--prev'),
                },

                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,

                        pagination: {
                            type: 'bullets',
                        },
                    },
                    1280: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        pagination: {
                            type: 'fraction',
                        },
                    },
                },
            });
        });

        const sliderAdvantages = document.querySelectorAll('.js-carusel-advantages');
        sliderAdvantages.forEach((slider) => {
            const advantagesSlider = new Swiper(slider, {
                slidesPerView: 'auto',
                spaceBetween: 14,
                slidesPerGroupAuto: true,
                freeMode: true,
                speed: 700,
                loop: false,
                grabCursor: true,
                enabled: true,

                breakpoints: {
                    1024: {
                        enabled: false,
                        spaceBetween: 0,
                    },
                },
            });
        });

        const sliderLogistic = document.querySelectorAll('.js-carusel-logistic ');
        sliderLogistic.forEach((slider) => {
            const wrapperCarusel = slider.closest('.logistic-carusel');
            const logisticSlider = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 14,
                slidesPerGroupAuto: true,
                freeMode: true,
                speed: 700,
                loop: false,
                grabCursor: true,
                enabled: true,

                pagination: {
                    el: wrapperCarusel?.querySelector('.carusel-pagination'),
                    type: "bullets",
                    clickable: true,
                },

                breakpoints: {
                    600: {
                        slidesPerView: 2,
                    },
                    1024: {
                        spaceBetween: 20,
                        slidesPerView: 3,
                    },
                },
            });
        });

        const sliderPartners = document.querySelectorAll('.js-carusel-partners');
        sliderPartners.forEach((slider) => {
            const partnersSlider = new Swiper(slider, {
                slidesPerView: 'auto',
                spaceBetween: 14,
                slidesPerGroupAuto: true,
                freeMode: true,
                speed: 700,
                loop: false,
                grabCursor: true,
                enabled: true,

                breakpoints: {
                    1024: {
                        enabled: false,
                        spaceBetween: 0,
                    },
                },
            });
        });

        const sliderHero = document.querySelectorAll('.js-carusel-hero');
        sliderHero.forEach((slider) => {
            const heroSlider = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 700,
                loop: true,
                grabCursor: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination-count",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + '0' + (index + 1) + "</span>";
                    },
                },
            });
        });
    },
    { once: true },
);
