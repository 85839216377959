import { loadYmap } from 'vue-yandex-maps';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        const mapBlock = document.querySelector('.js-ymap');

        const mapInit = async () => {
            await loadYmap({
                apiKey: mapBlock?.getAttribute('data-api-key'),
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1',
            });

            const myMap = new ymaps.Map(mapBlock.getAttribute('id'), {
                center: [55.725803, 37.688863],
                zoom: 17,
                controls: ['smallMapDefaultSet'],
            });

            let myGeoObjects: any[] = [];

            const showGeoObjects = (i: number) => {
                myMap.geoObjects.removeAll();
                myGeoObjects = [];

                const mapSections =
                    mapBlock?.querySelectorAll('.ymap__section');
                mapSections?.forEach((section, index) => {
                    if (i === index) {
                        const mapItems =
                            section.querySelectorAll('.ymap__item');
                        mapItems.forEach((item, index2) => {
                            const coordinates = item
                                .getAttribute('data-coordinates')
                                .split(',');
                            myGeoObjects.push({
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates,
                                },
                                options: {
                                    iconLayout: 'default#image',
                                    iconImageHref:
                                        item.getAttribute('data-pin'),
                                    iconImageSize: [37, 96],
                                    iconImageOffset: [-20, -96],
                                    hasHint: true,
                                },
                                properties: {
                                    clusterCaption:
                                        item.getAttribute('data-name'),
                                    balloonContentBody: item.innerHTML,
                                    hintContent:
                                        item.getAttribute('data-name'),
                                },
                            });
                        });

                        const ans = `{"type": "FeatureCollection", "features": ${JSON.stringify(
                            myGeoObjects,
                        )}}`;

                        const result = ymaps.geoQuery(myMap.geoObjects);
                        const extendedResult = result.add(ans);

                        const clusterer = extendedResult
                            .search('geometry.type == "Point"')
                            .clusterize({
                                gridSize: 50,
                                minClusterSize: 2,
                                clusterIconColor: '#238C47',
                                zoomMargin: 50,
                            });
                        myMap.geoObjects.add(clusterer);

                    }
                });
            };

            showGeoObjects(0);
        };

        if (mapBlock !== undefined && mapBlock !== null) {
            mapInit();
        }
    },

    { once: true },
);
