import { slideToggle } from '@/js/components/animation/toggle';
document.addEventListener('DOMContentLoaded', () => {
    const tabs = document.querySelectorAll('.js-tab-services');
    const tabItems = document.querySelectorAll('.services-tabs__list');

    tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
            e.preventDefault();

            if (window.matchMedia('(max-width: 1279px)').matches) {
                e.preventDefault();
                const target = tab?.nextElementSibling;
                slideToggle(tab, target, 300);
            } else {
                tab.classList.add('active');
                const tabId = tab.getAttribute('href') || '';
                tabItems.forEach((item, index) => {
                    if (item.getAttribute('id') === tabId.slice(1)) {
                        item.classList.remove('hide');
                    } else {
                        item.classList.add('hide');
                        tabs[index].classList.remove('active');
                    }
                });
            }
        });
    });
});
