import { slideToggle, slideUp } from './toggle';
document.addEventListener('DOMContentLoaded', () => {
    const toggleSolution = document.querySelectorAll('.js-toggle-solution');
    toggleSolution.forEach((solo) => {
        solo.addEventListener('click', (e) => {
            e.preventDefault();
            if (!solo.classList.contains('show-toggle')) {
                const soloItemsActive = document.querySelectorAll(
                    '.js-toggle-solution.show-toggle',
                );

                soloItemsActive.forEach((soloActive) => {
                    const parent = soloActive.parentElement;

                    if (parent !== null && parent !== undefined) {
                        if (parent.classList.contains('active')) {
                            parent.classList.remove('active');
                            soloActive.classList.remove('show-toggle');
                            slideUp(soloActive.nextElementSibling, 500);
                        }
                    }
                });
            }
            slideToggle(solo, solo.nextElementSibling, 510);
        });
    });
});