document.addEventListener(
    'DOMContentLoaded',
    () => {
        if (window.location.hash) {
            if (window.location.hash.includes('#anchor-')) {
                const blockId = window.location.hash.replace('#anchor-', '');
                if (blockId) {
                    setTimeout(() => {
                        window.location.hash = '#' + blockId;
                    }, 1000);
                }
            }
        }

        if (window.location.hash) {
            const targetElement = document.querySelector(window.location.hash);
            if (targetElement) {
                disableSmoothScroll();

                targetElement.scrollIntoView({ behavior: 'auto' });

                setTimeout(enableSmoothScroll, 100);
            }
        }

        function disableSmoothScroll() {
            document.documentElement.style.scrollBehavior = 'auto';
        }

        function enableSmoothScroll() {
            document.documentElement.style.scrollBehavior = 'smooth';
        }
    },
    { once: true },
);
