import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    Swiper.use([Navigation, Pagination]);

    const sliders = document.querySelectorAll('.js-advantages-carousel');
    sliders.forEach((slider) => {
        const parent = slider.closest('.swiper');
        const swiperSlder = new Swiper(slider, {
            speed: 700,
            parallax: true,
            grabCursor: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: parent?.querySelector('.swiper-button-next'),
                prevEl: parent?.querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: parent?.querySelector('.swiper-pagination'),
                type: 'fraction',
                clickable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    pagination: {
                        type: 'bullets',
                    },
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    pagination: {
                        type: 'fraction',
                    },
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    pagination: {
                        type: 'fraction',
                    },
                },
            },
        });
    });
});
