document.addEventListener('DOMContentLoaded', () => {
    const offices = document.querySelectorAll('.js-map-active');
    const mapItems = document.querySelectorAll('.map-pin');
    const footerMap = document.getElementById('map-footer');
    const btnLink = document.querySelectorAll('.js-btn-contacts-link');

    function activateOffice(officeId) {
        offices.forEach((office) => {
            office.classList.remove('active');
        });

        offices.forEach((office) => {
            if (office.getAttribute('href') === officeId) {
                office.classList.add('active');
            }
        });

        mapItems.forEach((item) => {
            item.classList.remove('active');
        });

        mapItems.forEach((item) => {
            if (item.getAttribute('id') === officeId) {
                item.classList.add('active');
            }
        });

        if (footerMap) {
            if (officeId !== '#pin-russia') {
                footerMap.style.display = 'none';
            } else {
                footerMap.style.display = 'flex';
            }
        }

        btnLink.forEach((item) => {
            item.style.display = 'none';
        });

        btnLink.forEach((item) => {
            if (item.getAttribute('data-id') === officeId) {
                item.style.display = 'inline-block';
            }
        });
    }

    offices.forEach((office) => {
        office.addEventListener('click', (e) => {
            e.preventDefault();

            const officeId = office.getAttribute('href') || '';
            activateOffice(officeId);
        });
    });

    mapItems.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();

            const officeId = item.getAttribute('id') || '';
            activateOffice(officeId);
        });
    });
});
