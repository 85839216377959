document.addEventListener('DOMContentLoaded', () => {
    document.body.addEventListener('click', (e) => {
        const productsWrapper = document.querySelector('.narrow-area');
        const target = e.target as Element;
        const link = target.closest('.js-content-load-more');
        if (link) {
            e.preventDefault();
            const url = link.getAttribute('href') || '';

            fetch(url)
                .then((response) => {
                    return response.text();
                })
                .then((data) => {
                    productsWrapper?.insertAdjacentHTML('beforeend', data);
                    const parent = link.closest('.content-load-more');
                    document.dispatchEvent(
                        new CustomEvent('AjaxContentLoaded')
                    );
                })
                .catch((err) => {
                    console.warn('Something went wrong.', err);
                });
        }
    });
});