import Isotope from 'isotope-layout';
document.addEventListener('DOMContentLoaded', () => {
    const grid = document.querySelector('.masonry-grid');
    if (grid !== null && grid !== undefined) {
        const iso = new Isotope(grid, {
            itemSelector: '.masonry-grid__item',
            layoutMode: 'masonry',
        });
    }
});
