document.addEventListener('DOMContentLoaded', () => {
    let targetScrollPercentage = 0;
    let currentScrollPercentage = 0;
    let animationContainer = document.querySelector('.js-history-progress');

    function updateAnimation() {
        currentScrollPercentage +=
            (targetScrollPercentage - currentScrollPercentage) * 0.1;

        let path = document.querySelector('#wavePath');
        let pathLength = path.getTotalLength();

        let pathFill = document.querySelector('#wavePathFill');
        pathFill.style.strokeDashoffset = pathLength - ((currentScrollPercentage * pathLength) + 35);

        let circle = document.querySelector('#scrollCircle');
        let pointAtLength = path.getPointAtLength(currentScrollPercentage * pathLength);
        circle.setAttribute('cx', pointAtLength.x);
        circle.setAttribute('cy', pointAtLength.y);

        requestAnimationFrame(updateAnimation);
    }

    if (animationContainer) {
        window.addEventListener('scroll', function() {
            let scrollPosition = window.scrollY;
            let documentHeight = document.documentElement.scrollHeight - window.innerHeight;
            targetScrollPercentage = scrollPosition / documentHeight;
        });

        updateAnimation();
    }
});
