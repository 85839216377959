document.addEventListener('DOMContentLoaded', () => {
    // Находим все элементы с классом js-wrapp-table
    const wrappers = document.querySelectorAll('.js-wrapp-table');

    // Для каждого такого блока
    wrappers.forEach(wrapper => {
        // Находим все таблицы внутри этого блока
        const tables = wrapper.querySelectorAll('table');

        tables.forEach(table => {
            // Создаём div с классом table
            const div = document.createElement('div');
            div.classList.add('table');

            // Вставляем div перед таблицей
            table.parentNode.insertBefore(div, table);

            // Перемещаем таблицу внутрь этого div
            div.appendChild(table);
        });
    });
});