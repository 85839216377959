gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

console.log(gsap);


const jsGsapTarget = document.querySelector('.js-scale-animate');
if (typeof jsGsapTarget !== 'undefined' && jsGsapTarget != null) {
    gsap.fromTo('.js-scale-animate', { scale: 0.75, y: 100 }, {
        scale: 1, y: 0,
        scrollTrigger: {
            trigger: '.js-scale-animate',
            start: '-1000',
            end: '-300',
            scrub: true,
            nullTargetWarn: false
        }
    })
}

const jsGsapTarget2 = document.querySelector('.js-scale-animate-2');
if (typeof jsGsapTarget2 !== 'undefined' && jsGsapTarget2 != null) {
    gsap.fromTo('.js-scale-animate-2', { scale: 0.75, y: 100 }, {
        scale: 1, y: 0,
        scrollTrigger: {
            trigger: '.js-scale-animate-2',
            start: '-1000',
            end: '-400',
            scrub: true,
            nullTargetWarn: false
        }
    })
}